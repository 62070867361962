import React, { useState } from "react";
import SelectBox from "../../components/SelectBox";
import InputBox from "../../components/InputBox";
import { addNewDoc } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function NewRequestForm({ addMode, handleAddMode }) {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const currentUser = useSelector((state) => state.userObject);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsProcessing(true);

    const docObject = {
      ...formData,
      dateRequested: new Date().toLocaleString(),
      requestedBy: currentUser.displayName,
      requestedByEmail: currentUser.email,
      status: "pending",
    };

    await addNewDoc("requests", docObject);
    toast.success("Request submitted successfully");
    handleSendEmailNotification();
    setIsProcessing(false);
    setFormData({});
    handleAddMode();
  };

  const handleSendEmailNotification = async () => {
    const recipients = ["aidi.tia@leechiu.com", "maricor.sabangan@leechiu.com"];
    const docObject = {
      requestor: currentUser.email,
      recipientName: "HR Department",
      recipientEmail: recipients,
      subject: "New HR Request Submitted" + " - " + formData.requestType,
      message: `A new HR request has been submitted by ${currentUser.displayName}. Please check the HR Requests page for more details.`,
      link: "https://myhr.leechiu.app/hr-requests",
    };

    const sendEmail = async () => {
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",
        body: JSON.stringify(docObject),
      });

      console.log("Email sent");
    };
    sendEmail();
  };

  const options = [
    { value: "Certificate of Employment", label: "Certificate of Employment" },
    { value: "Certificate of Leave", label: "Certificate of Leave" },
    { value: "Annual BIR 2316", label: "Annual BIR 2316" },
    { value: "HDMF List of Contribution", label: "HDMF List of Contribution" },
    {
      value: "HDMF List of Loans and Deductions",
      label: "HDMF List of Loans and Deductions",
    },
    {
      value: "Philhealth 9 Months List of Contribution",
      label: "Philhealth 9 Months List of Contribution",
    },
    { value: "Philhealth CS Form", label: "Philhealth CS Form" },
    { value: "Others", label: "Others" },
  ];

  const handleSelect = (selectedOption) => {
    setFormData({ ...formData, requestType: selectedOption.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="relative small-form max-w-[350px]">
      <div className="">
        <button className="close-button" onClick={handleAddMode}>
          Cancel
        </button>
      </div>

      <div className="pt-8">
        <form action="" onSubmit={handleSubmit}>
          <div className="header mb-4">Employee Request Form</div>
          <div className="space-y-4">
            <SelectBox
              name="requestType"
              label="Request type"
              placeholder={"Select Type of Request"}
              options={options}
              handleSelect={handleSelect}
              required
            />

            {formData.requestType === "Others" && (
              <InputBox
                name="description"
                type={"text"}
                label="Description of Request"
                placeholder="Description of Request"
                onChange={handleChange}
                required
                style={{
                  width: "100%",
                }}
              />
            )}

            <InputBox
              name="purpose"
              type={"text"}
              label="Purpose of Request"
              placeholder="Enter Purpose of Request"
              onChange={handleChange}
              required
              style={{
                width: "100%",
              }}
            />

            <InputBox
              name="dateRequired"
              type={"date"}
              label="Date Required"
              onChange={handleChange}
              required
              style={{
                width: "100%",
              }}
            />

            <div>
              <button
                className="blue-button disabled:bg-gray-600"
                disabled={isProcessing}
                type="submit"
              >
                {isProcessing ? "Submitting" : "Submit request"}
              </button>
            </div>
          </div>
        </form>

        <div className="mt-8 space-y-2">
          <div>
            <strong>Notice</strong>:
          </div>
          <div>
            Please ensure that all information provided are accurate and
            complete.
          </div>
          <div>
            Usual processing time is 3-5 working days. For urgent requests,
            please indicate in the purpose.
          </div>
        </div>
      </div>
    </div>
  );
}
