import React from "react";
import { motion } from "framer-motion";

function Modal({ children }) {
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ type: "tween" }}
        className="bg-white rounded-lg p-2"
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Modal;
