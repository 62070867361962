import React, { useEffect } from "react";
import { queryAllDocsByFieldSnap } from "../utils/firebase.utils";
import { useSelector } from "react-redux";
import RequestCard from "../pages/LeaveManagement/RequestCard";
import PageModal from "../components/PageModal";
import RequestDetails from "../pages/LeaveManagement/RequestDetails";

export default function LeaveRequestsForApproval() {
  const currentUser = useSelector((state) => state.userObject);
  const [requests, setRequests] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeFilter, setActiveFilter] = React.useState("Pending");
  const [viewMode, setViewMode] = React.useState(false);
  const [selectedRequest, setSelectedRequest] = React.useState({});

  useEffect(() => {
    const fetchRequests = async () => {
      await queryAllDocsByFieldSnap(
        "leaveRequests",
        "approver",
        currentUser.email,
        (data) => {
          setRequests(data);
          setIsLoading(false);
        }
      );
    };
    fetchRequests();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filters = new Set(requests.map((request) => request.status));
  const filtersArray = Array.from(filters);
  console.log(filters);

  const currentList = activeFilter
    ? requests.filter((request) => request.status === activeFilter)
    : requests;

  console.log(requests);

  const handleViewMode = (request) => {
    setViewMode(true);
    setSelectedRequest(request);
  };

  return (
    <div className="pt-8">
      <div>
        <div className="font-bold">Leave Requests for your approval</div>

        <div className="flex items-center gap-4 mt-4">
          {filtersArray.map((filter, index) => (
            <div
              key={index}
              className={
                activeFilter === filter ? "active-pill" : "inactive-pill"
              }
              onClick={() => setActiveFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>

        <div className="mt-8">
          {currentList.map((request, index) => (
            <div
              className="mt-2"
              key={index}
              onClick={() => handleViewMode(request)}
            >
              <RequestCard data={request} />
            </div>
          ))}
        </div>
      </div>

      {currentList.length === 0 && (
        <div>You have no requests pending for approval</div>
      )}

      {viewMode && (
        <PageModal>
          <RequestDetails data={selectedRequest} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
