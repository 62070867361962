import React, { useEffect, useState } from "react";

import LeaveRequestPage from "./LeaveRequestPage";

export default function LeavesMainPage() {
  const [activeLink, setActiveLink] = useState("Leave Request");
  const links = ["Leave Request", "Leave History"];

  return (
    <div className="pt-8 text-gray-600 text-left">
      <div className="header">Leave Management</div>

      <div className="links-container">
        {links.map((link, index) => (
          <button
            key={index}
            className={
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(link)}
          >
            {link}
          </button>
        ))}
      </div>

      {activeLink === "Leave Request" && <LeaveRequestPage />}
    </div>
  );
}
