import React from "react";

export default function RequestCard({ data }) {
  return (
    <div className="card relative">
      <div className="flex items-start lg:items-center justify-normal flex-col lg:flex-row gap-2 lg:gap-8">
        <div className="space-y-1 w-full lg:w-36 flex flex-row lg:flex-col gap-2 items-center lg:items-start">
          <div className="w-16 bg-gray-100 px-1 rounded-lg text-[8px]">
            Requestor
          </div>
          <div className="font-bold text-leechiuBlue">{data.name}</div>
        </div>
        <div className="space-y-1 w-full lg:w-36 flex flex-row lg:flex-col gap-2 items-center lg:items-start">
          <div className="w-16 bg-gray-100 px-1 rounded-lg text-[8px]">
            Type of Leave
          </div>
          <div className="font-bold text-leechiuBlue">{data.leaveType}</div>
        </div>

        <div className="space-y-1 w-full lg:w-36 flex flex-row lg:flex-col gap-2 items-center lg:items-start">
          <div className="w-16 bg-gray-100 px-1 rounded-lg text-[8px]">
            Request Date
          </div>
          <div className=" font-bold text-leechiuBlue">{data.createdAt}</div>
        </div>

        <div className="space-y-1 w-full lg:w-36 flex flex-row lg:flex-col gap-2 items-center lg:items-start">
          <div className="w-16 bg-gray-100 px-1 rounded-lg text-[8px]">
            Start Date
          </div>
          <div className="font-bold text-leechiuBlue">{data.startDate}</div>
        </div>

        <div className="space-y-1 w-full lg:w-36 flex flex-row lg:flex-col gap-2 items-center lg:items-start">
          <div className="w-16 bg-gray-100 px-1 rounded-lg text-[8px]">
            End Date
          </div>
          <div className="font-bold text-leechiuBlue">{data.endDate}</div>
        </div>

        <div className="space-y-1 w-full lg:w-36 flex flex-row lg:flex-col gap-2 items-center lg:items-start">
          <div className="w-16 bg-gray-100 px-1 rounded-lg text-[8px]">
            No. of Days
          </div>
          <div className="font-bold text-leechiuBlue">{data.numberOfDays}</div>
        </div>

        <div className="space-y-1 absolute lg:block top-2 right-2">
          <div className={data.status.toLowerCase()}>{data.status}</div>
        </div>
      </div>
    </div>
  );
}
