import React from "react";
import { useParams } from "react-router-dom";
import { getDocDataSnap, setDocData } from "../../utils/firebase.utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../utils/firebase.utils";
import { useState, useEffect } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "./Modal";
import InputBox from "../../components/InputBox";

function Attachments() {
  const { id } = useParams();
  const [employee, setEmployee] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [addMode, setAddMode] = React.useState(false);
  const [uploadingItem, setUploadingItem] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [formData, setFormData] = React.useState({});

  const requiredAttachments = [
    {
      name: "NBI Clearance",
      description: "This is required for security purposes",
      isRequired: true,
    },
    {
      name: "Transcript of Records",
      description: "This is required for educational purposes",
      isRequired: true,
    },
    {
      name: "SSS ID",
      description: "This is required for government purposes",
      isRequired: true,
    },
    {
      name: "PhilHealth ID",
      description: "This is required for health purposes",
      isRequired: true,
    },
    {
      name: "Pag-IBIG ID",
      description: "This is required for housing purposes",
      isRequired: true,
    },
    {
      name: "TIN ID",
      description: "This is required for tax purposes",
      isRequired: true,
    },
    {
      name: "Resume / CV",
      description: "CV submitted during application",
      isRequired: true,
    },
  ];

  const [attachmentList, setAttachmentList] =
    React.useState(requiredAttachments);

  useEffect(() => {
    async function fetchEmployee() {
      try {
        const employee = await getDocDataSnap("employees", id, (employee) => {
          setEmployee(employee);
          setIsLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchEmployee();
  }, []);

  //   merge uploaded attachments and required attachments
  useEffect(() => {
    if (employee) {
      const arrayOfAttachments = Object.keys(employee.attachments || {}).map(
        (key) => ({
          name: key,
          ...employee.attachments[key],
        })
      );
      const mergedAttachments = [...requiredAttachments, ...arrayOfAttachments];
      const uniqueAttachments = mergedAttachments.filter(
        (v, i, a) => a.findIndex((t) => t.name === v.name) === i
        );
        setAttachmentList(uniqueAttachments);
        }
     
  }, [employee]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleUpload = async (file, attachment) => {
    const storageRef = ref(storage, `attachments/${id}/${attachment.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    let URL = "";

    setUploadingItem(attachment.name);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          URL = downloadURL;
          //create object map of attachments
          const updatedAttachments = {
            ...employee.attachments,
            [attachment.name]: {
              url: URL,
              description: attachment.description,
            },
          };

          try {
            setDocData("employees", id, {
              attachments: updatedAttachments,
            });

            setUploadingItem(null);
            setProgress(0);
          } catch (error) {
            console.error(error);
          }
        });
      }
    );
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddRequiredAttachment = (e) => {
    e.preventDefault();
    const { attachmentName, attachmentDescription } = formData;

    const attachment = {
      name: attachmentName,
      description: attachmentDescription,
      isRequired: true,
    };

    attachmentList.push(attachment);
    setAddMode(false);
    setFormData({});
  };

  return (
    <div p-2>
      <div className="font-bold text-leechiuBlue text-sm text-left">
        Attachments
      </div>
      <div className="text-left my-2">
        <button
          className="bg-leechiuBlue text-white px-2 py-1 rounded-lg"
          onClick={() => setAddMode(true)}
        >
          Add document
        </button>
      </div>
      <div className="flex flex-wrap gap-4">
        {attachmentList.map((attachment, index) => (
          <div key={index} className="bg-white p-2 rounded-lg shadow-md">
            <div className="font-bold text-leechiuBlue text-sm flex items-center gap-4 justify-center">
              {employee.attachments && employee.attachments[attachment.name] ? (
                <a
                  href={employee.attachments[attachment.name].url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="text-lg text-green-800">
                    <CheckCircleIcon />
                  </div>
                </a>
              ) : (
                <CancelIcon className="text-red-600" />
              )}

              <a
                href={
                  employee.attachments &&
                  employee.attachments[attachment.name] &&
                  employee.attachments[attachment.name].url
                }
                target="_blank"
              >
                {attachment.name}
              </a>
            </div>

            <div className="text-gray-400 text-xs">
              {attachment.description}
            </div>

            {uploadingItem === attachment.name && (
              <div
                className="mt-2"
                style={{
                  width: `${progress}%`,
                  height: "5px",
                  backgroundColor: "green",
                }}
              ></div>
            )}

            {/* progress bar */}

            <input
              type="file"
              onChange={(e) => handleUpload(e.target.files[0], attachment)}
            />
          </div>
        ))}
      </div>

      {addMode && (
        <Modal>
          <div className="bg-white p-4 w-[800px] rounded-xl relative ">
            <button
              className="absolute top-2 right-2"
              onClick={() => {
                setAddMode(false);
                setFormData({});
              }}
            >
              Close
            </button>

            <div className="text-left font-bold">Add Attachment</div>
            <div className="w-full">
              <form
                className="w-full space-y-2 mt-2 flex items-center justify-start flex-wrap gap-4"
                onSubmit={handleAddRequiredAttachment}
              >
                <InputBox
                  label="Attachment Name"
                  name="attachmentName"
                  type="text"
                  placeholder="Attachment Name"
                  value={formData.attachmentName}
                  onChange={handleChange}
                  required
                />
                <InputBox
                  label="Attachment Description"
                  name="attachmentDescription"
                  type="text"
                  placeholder="Attachment Description"
                  value={formData.attachmentDescription}
                  onChange={handleChange}
                  required
                />

                <button
                  className="bg-leechiuBlue text-white px-2 py-1 rounded-lg"
                  type="submit"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Attachments;
