import React from "react";

function WhiteButton({ label, onClick, ...otherprops }) {
  return (
    <div>
      <button
        className=" hover:bg-gray-100 border border-black py-2 px-4 rounded bg-white"
        onClick={onClick}
        {...otherprops}
      >
        {label}
      </button>
    </div>
  );
}


export default WhiteButton;
