import React, { useEffect, useState } from "react";
import NewRequestForm from "./NewRequestForm";
import Modal from "../Employees/Modal";
import { queryAllDocsByFieldSnap } from "../../utils/firebase.utils";
import RequestCard from "./RequestCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RequestFormv2 from "./RequestFormv2";

export default function UserRequest() {
  const [addMode, setAddMode] = useState(false);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    setLoading(true);
    const getRequests = async () => {
      const requests = await queryAllDocsByFieldSnap(
        "requests",
        "requestedByEmail",
        currentUser.email,
        (data) => {
          //arrange data by dateREquested
          data.sort((a, b) => {
            return new Date(b.dateRequested) - new Date(a.dateRequested);
          });
          setRequests(data);
          setLoading(false);
        }
      );
    };

    getRequests();
  }, []);

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="header text-lg text-left mb-8">Send HR Request</div>

      <div>
        <button className="blue-button" onClick={handleAddMode}>
          Create a request
        </button>
      </div>

      <div className="mt-8 space-y-2">
        {requests.map((request, index) => (
          <div key={index}>
            <Link to={`/request/${request.id}`}>
              <RequestCard request={request} />
            </Link>
          </div>
        ))}
      </div>
      {requests.length === 0 && (
        <div className="text-center mt-8">
          <div className="text-2xl">You have no requests on file</div>
          <div className="text-gray-500">
            Click the button above to create your first request
          </div>
        </div>
      )}
      {/* <div className="w-[400px]">
        <RequestFormv2 />
      </div> */}

      {addMode && (
        <Modal>
          <NewRequestForm addMode={addMode} handleAddMode={handleAddMode} />
        </Modal>
      )}
    </div>
  );
}
