import React from "react";
import Button from "../../components/Button";
import { useState, useEffect } from "react";
import { getAllDocsSnap, createNewDocument } from "../../utils/firebase.utils";
import PayrollDetails from "./PayrollDetails";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function Payroll() {
  const [editMode, setEditMode] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [payrollRunDate, setPayrollRunDate] = useState(null);
  const [payrollRuns, setPayrollRuns] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [submittedPayroll, setSubmittedPayroll] = useState([]);
  const [approvedPayroll, setApprovedPayroll] = useState([]);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchPayrollRuns = async () => {
      const data = getAllDocsSnap("payrollRuns", (data) => {
        const drafts = data.filter((item) => item.status === "draft");
        const submitted = data.filter((item) => item.status === "submitted");
        const approved = data.filter((item) => item.status === "approved");

        // arranged approved payroll by date
        approved.sort((a, b) => {
          // convert date to milliseconds
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();

          return dateA - dateB;
        });

        setPayrollRuns(drafts);
        setSubmittedPayroll(submitted);
        setApprovedPayroll(approved);
      });
    };
    fetchPayrollRuns();
  }, []);

  const handleSss = (basic) => {
    const limit = 29750;
    const baseline = 4250;
    const increment = 22.5;
    const multiple = 500;
    const baseSSS = 202.5;
    const minSSS = 180;
    const maxSSS = 1350;

    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic >= limit) {
      return maxSSS / 2;
    } else if (basic < baseline) {
      return minSSS / 2;
    } else {
      const sss =
        baseSSS + Math.floor((basic - baseline) / multiple) * increment;
      return sss / 2;
    }
  };

  const handlePagibig = (basic) => {
    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic <= 1500) {
      return (basic * 0.02) / 2;
    } else if (basic > 5000) {
      return 200 / 2;
    }
  };

  const handlePhilhealth = (basic) => {
    if (basic === "") return 0;
    if (basic <= 0) {
      return 0;
    } else if (basic <= 10000) {
      return 500 / 4;
    } else if (basic > 100000) {
      return 5000 / 4;
    } else return (basic * 0.05) / 4;
  };

  const handleThirteenMonth = (basic) => {
    let thirteenMonth = 0;
    if (basic === "") {
      return 0;
    } else {
      thirteenMonth = basic / 24;
      return thirteenMonth;
    }
  };

  const handleTax = (basic) => {
    // change this formula
    let taxableThirteenMonth = 0;
    let sss = handleSss(basic);
    let philhealth = handlePhilhealth(basic);
    let pagibig = handlePagibig(basic);
    let thirteenMonth = handleThirteenMonth(basic);
    let tax = 0;

    taxableThirteenMonth = Math.max(0, thirteenMonth * 24 - 90000);

    const annual =
      (basic / 2 - sss - philhealth - pagibig) * 24 + taxableThirteenMonth;
    if (annual <= 250000) {
      tax = 0;
    } else if (annual <= 400000) {
      tax = (annual - 250000) * 0.15;
    } else if (annual <= 800000) {
      tax = 22500 + (annual - 400000) * 0.2;
    } else if (annual <= 2000000) {
      tax = 102500 + (annual - 800000) * 0.25;
    } else if (annual <= 8000000) {
      tax = 402500 + (annual - 2000000) * 0.3;
    } else if (annual > 8000000) {
      tax = 2202500 + (annual - 8000000) * 0.35;
    }
    return tax / 24;
  };

  const handleSavePayrollRun = () => {
    if (payrollRunDate === null || payrollRunDate === undefined) {
      return;
    }
    const newPayrollRun = async () => {
      const data = {
        date: payrollRunDate,
        status: "draft",
        payregister: employees,
      };
      try {
        const res = await createNewDocument("payrollRuns", data);
      } catch (error) {
      }
    };
    newPayrollRun();
    setEditMode(false);
    toast.success("Payroll Run Created");
  };

  const handleSelectDate = (e) => {
    setPayrollRunDate(e.target.value);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      const data = getAllDocsSnap("employees", (data) => {
        //map the data
        let basicSalary = 0;
        const mappedData = data.map((item) => {
          if (item.basicSalary === undefined) {
            basicSalary = 100000;
            return {
              id: item.id,
              displayName: item.displayName,
              employeeNumber: item.employeeNumber || "N/A",
              lastName: item.lastName,
              firstName: item.firstName,
              basicSalary: 0,
              bankAccount: item.bankAccount || "N/A",
              halfMonth: 0,
              SSS: handleSss(basicSalary).toFixed(2),
              HDMF: handlePagibig(basicSalary).toFixed(2),
              Philhealth: handlePhilhealth(basicSalary).toFixed(2),
              thirteenMonth: handleThirteenMonth(basicSalary).toFixed(2),
              reimbursement: 0,
              withholdingTax: handleTax(0).toFixed(2),
              netPay: parseFloat(
                0 -
                  handleSss(basicSalary) -
                  handlePagibig(basicSalary) -
                  handlePhilhealth(basicSalary) -
                  handleTax(0)
              ).toFixed(2),
              commission: 0,
              incentives: 0,
              lwop: 0,
              salaryAdjustment: 0,
              overtime: 0,
              otherDeductions: 0,
              advances: 0,
              maxicare: 0,
              hdmf2: 0,
              hdmfloan: 0,
              sssloan: 0,
            };
          } else {
            basicSalary = item.basicSalary;
          }
          return {
            id: item.id,
            displayName: item.displayName,
            employeeNumber: item.employeeNumber || "N/A",
            lastName: item.lastName,
            firstName: item.firstName,
            basicSalary: basicSalary,
            bankAccount: item.bankAccount || "N/A",
            halfMonth: parseFloat(basicSalary / 2).toFixed(2),
            SSS: handleSss(basicSalary).toFixed(2),
            HDMF: handlePagibig(basicSalary).toFixed(2),
            Philhealth: handlePhilhealth(basicSalary).toFixed(2),
            thirteenMonth: handleThirteenMonth(basicSalary).toFixed(2),
            reimbursement: 0,
            withholdingTax: handleTax(basicSalary).toFixed(2),
            netPay: parseFloat(
              basicSalary / 2 -
                handleSss(basicSalary) -
                handlePagibig(basicSalary) -
                handlePhilhealth(basicSalary) -
                handleTax(basicSalary)
            ).toFixed(2),
            commission: 0,
            incentives: 0,
            lwop: 0,
            salaryAdjustment: 0,
            overtime: 0,
            otherDeductions: 0,
            advances: 0,
            maxicare: 0,
            hdmf2: 0,
            hdmfloan: 0,
            sssloan: 0,
          };
        });
        setEmployees(mappedData);
      });
    };
    fetchEmployees();
  }, []);

  return (
    <div className="mt-4 px-4 py-2">
      <div className="font-semibold text-lg">Payroll Management</div>
      <div>
        Notice: Make sure to update salary information in 201 prior to running
        payroll
      </div>

      <div className="text-left">
        <Button
          label={editMode ? "Cancel" : "Run Payroll"}
          onClick={() => setEditMode(!editMode)}
        />
      </div>
      {editMode && (
        <div className="text-left flex items-center gap-2 mt-4 bg-white py-4 px-2 rounded-xl w-fit shadow-lg">
          <label>Select Payroll Date</label>
          <input
            type="date"
            className="px-4 py-2 rounded-xl bg-gray-100"
            name="payrollRunDate"
            onChange={handleSelectDate}
          />
          <Button label="Run" onClick={handleSavePayrollRun} />
        </div>
      )}
      {/* {editMode && (
        <div className="py-4 px-2 rounded-xl shadow-lg bg-white mt-4">
          <PayrollDetails
            employees={employees}
            setSelectedEmployee={setSelectedEmployee}
            selectedEmployee={selectedEmployee}
          />
        </div>
      )} */}

      {currentUser.roles.includes("maker") && (
        <div className="text-left mt-8">
          <div className="font-semibold text-orange-600">
            Select Below for Draft Payroll Runs
          </div>
          <div className="flex flex-col gap2">
            {payrollRuns.map((item) => (
              <Link to={`/draftpayroll/${item.id}`} key={item.id}>
                {item.date}
              </Link>
            ))}
          </div>
        </div>
      )}

      {currentUser.roles.includes("approver") ||
      currentUser.roles.includes("maker") ? (
        <div className="text-left mt-8">
          <div className="font-semibold text-orange-600">
            Payroll Pending Review and Approval
          </div>
          <div className="flex flex-col gap2">
            {submittedPayroll.map((item) => (
              <Link to={`/payrollviewmode/${item.id}`} key={item.id}>
                {item.date}
              </Link>
            ))}
          </div>
        </div>
      ) : null}

      {currentUser.roles.includes("approver") ||
      currentUser.roles.includes("maker") ? (
        <div className="text-left mt-8">
          <div className="font-semibold text-orange-600">Payroll Approved</div>
          <div className="flex flex-col gap2">
            {approvedPayroll.map((item) => (
              <Link to={`/payrollviewmode/${item.id}`} key={item.id}>
                {item.date}
              </Link>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Payroll;
