import React, { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { getAllDocsSnap } from "../utils/firebase.utils";
import EmployeeBreakdown from "./Dashboard/EmployeeBreakdown";

function AdminDashboard() {
  const [employees, setEmployees] = React.useState([]);
  const [activeEmployees, setActiveEmployees] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [probationary, setProbationary] = React.useState([]);
  const [pendingApproval, setPendingApproval] = React.useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      await getAllDocsSnap("employees", (data) => {
        //arrange data by lastName
        data.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        });

        setEmployees(data);

        //filter not resigned and not pending approval
        const active = data.filter(
          (employee) =>
            employee.status !== "Resigned" &&
            employee.status !== "pending approval"
        );

        const proby = data.filter(
          (employee) => employee.status.toLowerCase() === "probationary"
        );

        const pending = data.filter(
          (employee) => employee.status.toLowerCase() === "pending approval"
        );
        setPendingApproval(pending);
        setProbationary(proby);
        setActiveEmployees(active);
        setIsLoading(false);
      });
    };
    fetchEmployees();
  }, []);

  const empSummaryData = [
    {
      title: "Number of Active Employees",
      value: activeEmployees.length,
      increase: "-5%",
    },
    {
      title: "Number of Probationary",
      value: probationary.length,
      increase: "-5.38%",
    },
    {
      title: "New Employees",
      value: pendingApproval.length,
      increase: "6.25%",
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const AllDepartments = activeEmployees.map((employee) => employee.department);
  const uniqueDepartments = [...new Set(AllDepartments)];

  console.log(uniqueDepartments);

  const breakdownPerDepartment = uniqueDepartments.map((department) => {
    const employeesInDepartment = activeEmployees.filter(
      (employee) => employee.department === department
    );
    return {
      department: department,
      total: employeesInDepartment.length,
    };
  });

  return (
    <div className="bg-white rounded-xl shadow-md h-full p-4 ">
      {/* header portion */}
      <div className="w-full h-32  border-gray-400 border-[1px] rounded-lg flex items-center justify-around flex-wrap">
        <div className="flex flex-col items-start justify-start">
          <div className="text-lg capitalize font-bold text-leechiuBlue">
            Overview
          </div>
          <div className="text-sm capitalize">Select from functions below</div>
        </div>
        <div className="flex items-center justify-center gap-2">
          <FiSearch className="text-gray-400 text-xl" />
          <input
            className="w-60 h-10 border-gray-400 border-[1px] rounded-lg px-2 py-1"
            type="text"
            placeholder="Search Here"
          />
        </div>
      </div>

      {/* body portion */}
      <div className="w-full flex items-center justify-start gap-4 mt-4 flex-wrap">
        <div>
          <div className="flex items-center justify-center gap-2">
            {empSummaryData.map((data, index) => {
              return (
                <div
                  key={index}
                  className="text-left border-left-gray-400 px-2"
                  style={{
                    borderLeft: "2px solid #e2e8f0",
                  }}
                >
                  <div className="text-gray-500 text-xs">{data.title}</div>
                  <div className="text-leechiuBlue text-xl font-bold">
                    {data.value}
                  </div>
                  <div
                    className="text-xs font-semibold"
                    style={{
                      color: data.increase.includes("-") ? "red" : "green",
                    }}
                  >
                    {parseFloat(data.increase)}{" "}
                    <span className="text-gray-400 ">last month</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <EmployeeBreakdown
              breakdownPerDepartment={breakdownPerDepartment}
            />
          </div>
          <div>
            <ShortCuts />
          </div>
        </div>
      </div>
    </div>
  );
}

const ShortCuts = () => {
  const [activeShortCut, setActiveShortCut] = React.useState("Documents");

  return (
    <div className="border-2  rounded-lg mt-4">
      <div
        className="flex items-center justify-between px-2 py-1"
        style={{
          borderBottom: "1px solid #e2e8f0",
        }}
      >
        <div className="uppercase text-xs font-bold text-leechiuBlue">
          Shortcut
        </div>
        <div
          className="flex items-center justify-center  rounded-lg divide-x-2"
          style={{
            border: "1px solid #e2e8f0",
          }}
        >
          <div
            className={` text-xs font-semibold  p-2 cursor-pointer *:
               ${
                 activeShortCut === "Documents"
                   ? "bg-leechiuOrange text-white"
                   : "text-leechiuBlue"
               }

              `}
            style={{
              borderRadius: "5px 0 0 5px",
            }}
            onClick={() => setActiveShortCut("Documents")}
          >
            Documents
          </div>
          <div
            className={` text-xs font-semibold  p-2 cursor-pointer *:
            ${
              activeShortCut === "People"
                ? "bg-leechiuOrange text-white"
                : "text-leechiuBlue"
            }

           `}
            onClick={() => setActiveShortCut("People")}
          >
            People
          </div>
          <div
            className={` text-xs font-semibold p-2 cursor-pointer *:
            ${
              activeShortCut === "Others"
                ? "bg-leechiuOrange text-white"
                : "text-leechiuBlue"
            }

           `}
            onClick={() => setActiveShortCut("Others")}
          >
            Others
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
