import React from "react";
import Select from "react-select";

function SelectBox({
  options,
  label,
  handleSelect,
  value,
  placeholder,
  isMulti,
  name,
  disabled,
}) {
  return (
    <div>
      <label className="capitalize font-bold">{label || "Label"}</label>
      <Select
        isDisabled={disabled}
        name={name}
        options={options}
        onChange={handleSelect}
        value={value}
        placeholder={placeholder}
        isMulti={isMulti}
      />
    </div>
  );
}

export default SelectBox;
