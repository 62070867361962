import React from "react";
import PayrollDetails from "./PayrollDetails";
import { useState, useEffect } from "react";
import {
  getAllDocsSnap,
  getDocDataSnap,
  setDocData,
} from "../../utils/firebase.utils";
import { useParams } from "react-router-dom";
import Payslip from "./Payslip";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import WhiteButton from "../../components/WhiteButton";
import { useSelector } from "react-redux";
import DownloadExcel from "../../components/DownloadExcel";
import InputBox from "../../components/InputBox";

function PayrollViewMode() {
  const [payroll, setPayroll] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employeePayrollDetails, setEmployeePayrollDetails] = useState([]);
  const [bankFile, setBankFile] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [empFirstNames, setEmpFirstNames] = useState([]);
  const [filteredPayroll, setFilteredPayroll] = useState([]);
  const [totalNetPay, setTotalNetPay] = useState(0);

  const currentUser = useSelector((state) => state.userObject);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      const res = getAllDocsSnap("employees", (data) => {
        setEmployeeList(data);
      });
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchPayroll = async () => {
      const data = await getDocDataSnap("payrollRuns", id, (data) => {
        //arrange data by surname
        data.payregister.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
        setPayroll(data);
        setFilteredPayroll(data.payregister);

        const bpiFile = data.payregister.map((item) => {
          //get the firstName and bankAccount
          const firstAndBankAccount = employeeList.find(
            (emp) => emp.id === item.id
          );

          return {
            lastName: item.lastName,
            firstName: firstAndBankAccount?.firstName,
            bankAccount: firstAndBankAccount?.bankAccount,
            netPay: parseFloat(item.netPay.replace(/,/g, "")),
          };
        });

        //sort by displayName
        bpiFile.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
        setBankFile(bpiFile);
        setIsLoading(false);
      });
    };
    fetchPayroll();
  }, [employeeList]);

  useEffect(() => {
    if (selectedEmployee.length > 0) {
      const data = payroll.payregister.filter(
        (item) => item.id === selectedEmployee[0]
      );
      setEmployeePayrollDetails(data[0]);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (filteredPayroll.length > 0) {
      const total = filteredPayroll.reduce((acc, item) => {
        return acc + parseFloat(item.netPay.replace(/,/g, ""));
      }, 0);
      setTotalNetPay(
        total.toLocaleString(undefined, { minimumFractionDigits: 2 })
      );
    }
  }, [filteredPayroll]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleApproval = () => {
    if (window.confirm("Are you sure you want to approve this payroll?")) {
      const data = {
        ...payroll,
        status: "approved",
      };
      setDocData("payrollRuns", id, data).then(() => {
        navigate("/payroll");
      });
    } else {
      return;
    }
  };

  const handleReject = () => {
    if (window.confirm("Are you sure you want to return this payroll?")) {
      const data = {
        ...payroll,
        status: "draft",
      };
      setDocData("payrollRuns", id, data).then(() => {
        navigate("/payroll");
      });
    } else {
      return;
    }
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    const filtered = payroll.payregister.filter((item) =>
      item.displayName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredPayroll(filtered);
  };

  const bankDownloadFile = bankFile.filter((item) => item.netPay > 0);

  return (
    <div className="p-4 flex flex-col gap-4 ">
      <div className="text-left flex flex-col gap-2 mb-4">
        <div className="text-left text-orange-600 font-semibold">
          {payroll.status === "submitted"
            ? "Payroll Pending Review and Approval"
            : payroll.status === "approved"
            ? "Payroll Approved"
            : null}
        </div>
        <div>Payroll run date: {payroll.date}</div>
        <div>Total Net Pay: {totalNetPay}</div>
      </div>
      <InputBox
        label="Search name"
        placeholder="Search name"
        onChange={handleSearch}
        type="search"
      />
      <div className="rounded-xl shadow-lg bg-white p-4">
        <PayrollDetails
          employees={filteredPayroll}
          setSelectedEmployee={setSelectedEmployee}
        />
      </div>

      {payroll.status === "submitted" && selectedEmployee.length === 0
        ? currentUser.roles.includes("approver") && (
            <div className="flex items-center gap-2">
              <Button label="Approve" onClick={handleApproval} />
              <WhiteButton label="Reject Payroll" onClick={handleReject} />
            </div>
          )
        : null}

      <div>
        {selectedEmployee.length > 0 && (
          <div className="bg-white p-4 rounded-xl shadow-lg">
            <Payslip
              payrollDetails={employeePayrollDetails}
              employee={selectedEmployee}
              editDisabled={true}
            />
          </div>
        )}
      </div>

      {selectedEmployee < 1 && (
        <div className="text-left text-xs">
          <DownloadExcel
            data={payroll.payregister}
            filename="payroll"
            label={"Download Excel Payroll File"}
          />
        </div>
      )}

      {payroll.status === "approved" && selectedEmployee < 1 ? (
        <div className="text-left text-xs">
          <DownloadExcel
            data={bankDownloadFile}
            filename="Bank File"
            label={"Download Bank File"}
          />
        </div>
      ) : null}
    </div>
  );
}

export default PayrollViewMode;
