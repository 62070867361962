import React from "react";
import { motion } from "framer-motion";

export default function PageModal({ children }) {
  return (
    <div className="absolute top-0 right-0 w-screen bg-black bg-opacity-50 h-screen overflow-y-scroll z-20 flex items-start justify-center p-2 lg:p-8">
      <motion.div
        className="flex items-start justify-center w-screen min-h-screen"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    </div>
  );
}
