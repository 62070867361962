import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SelectBox from "../../components/SelectBox";
import { setDocData, getDocDataSnap } from "../../utils/firebase.utils";
import toast from "react-hot-toast";
import { Subject } from "@mui/icons-material";

export default function RequestDetails({ data, setViewMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [updated, setUpdated] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState("");
  const [formData, setFormData] = React.useState(data);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [comments, setComments] = React.useState(data.comments || []);
  const [newComment, setNewComment] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      await getDocDataSnap("leaveRequests", data.id, (doc) => {
        setFormData(doc);
        setComments(doc.comments || []);
      });
    };
    fetchData();
  }, []);

  const statuses = ["Pending", "Approved", "Rejected", "Returned"];
  const statusOptions = statuses.map((status) => ({
    value: status,
    label: status,
  }));

  const handleSelectNewStatus = (status) => {
    setNewStatus(status.value);
    setUpdated(true);
  };

  const handleUpdate = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: newStatus,
      updatedBy: currentUser.displayName,
      updatedEmail: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      updateType: "status is updated to " + newStatus,
      approvedAt: newStatus === "Approved" ? new Date().toLocaleString() : "",
    };
    await setDocData("leaveRequests", data.id, docObject);
    toast.success("Status updated successfully");
    setIsProcessing(false);
    setUpdated(false);
    sendStatusUpdateNotification();
  };

  const sendStatusUpdateNotification = async () => {
    const notificationObject = {
      recipientEmail: data.requestedBy,
      recipientName: data.name,
      requestor: currentUser.email,
      subject: "Leave Request Status Update",
      message: `Your leave request status has been updated to ${newStatus}`,
      link: "https://myhr.leechiu.app/leaves",
    };

    const sendEmail = async () => {
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",
        body: JSON.stringify(notificationObject),
      });

      console.log("Email sent");
    };
    sendEmail();
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    const commentObject = {
      message: newComment,
      createdBy: currentUser.displayName,
      createdAt: new Date().toLocaleString(),
    };
    const docObject = {
      ...formData,
      comments: [...comments, commentObject],
    };
    await setDocData("leaveRequests", data.id, docObject);
    toast.success("Comment added successfully");
    setIsProcessing(false);
    setNewComment("");
  };

  // compute for number of days excluding weekends
  const getBusinessDays = (startDate, endDate) => {
    let count = 0;
    let curDate = new Date(startDate);
    while (curDate <= new Date(endDate)) {
      const dayOfWeek = curDate.getDay();
      if (![0, 6].includes(dayOfWeek)) {
        count++;
      }
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  };

  return (
    <div className="page flex flex-col lg:flex-row items-start gap-4 lg:gap-8">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>

      <div className="mt-8 min-w-80 space-y-1">
        <div className="header">Leave Request Details</div>
        <div className="flex items-center justify-between">
          <div>Requestor</div>
          <div>{data.name}</div>
        </div>
        <div className="flex items-center justify-between">
          <div>Type of Leave</div>
          <div>{data.leaveType}</div>
        </div>

        <div className="flex items-center justify-between">
          <div>Number of Days</div>
          <div>{data.numberOfDays}</div>
        </div>

        <div className="flex items-center justify-between">
          <div>Start Date</div>
          <div>{data.startDate}</div>
        </div>

        <div className="flex items-center justify-between">
          <div>End Date</div>
          <div>{data.endDate}</div>
        </div>

        <div className="flex flex-col items-start">
          <div>Reason</div>
          <div>{data.reason}</div>
        </div>

        <div className="flex items-center justify-between">
          <div>Status</div>
          <div className={formData.status.toLowerCase()}>{formData.status}</div>
        </div>

        <div className="pt-8">
          {data.approver === currentUser.email && (
            <div>
              <div className="header">Approver Action</div>
              <div className="mt-2">
                <SelectBox
                  label="Update Status"
                  options={statusOptions}
                  placeholder={"Select New Status"}
                  handleSelect={(selected) => handleSelectNewStatus(selected)}
                />
              </div>
              <button
                className="blue-button mt-4"
                disabled={!updated}
                onClick={handleUpdate}
              >
                {isProcessing ? "Processing..." : "Update Status"}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="w-full mt-8">
        <div className="header">Comments</div>
        <div>
          <textarea
            name="comment"
            rows={4}
            placeholder="Add Comments"
            className="w-full rounded-lg p-2 border"
            id=""
            onChange={handleChange}
            value={newComment}
          ></textarea>
          <button className="blue-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>

        <div className="mt-8">
          {comments.map((comment, index) => (
            <div className="comment" key={index}>
              <div className="text-xs font-semibold">{comment.createdBy}</div>
              <div className="text-sm">{comment.message}</div>
              <div>{comment.createdAt}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
