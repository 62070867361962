import React from "react";
import * as XLSX from "xlsx";

function DownloadExcel({ label, data, filename }) {
  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      onClick={downloadExcel}
    >
      {label}
    </button>
  );
}

export default DownloadExcel;
