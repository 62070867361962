import React from "react";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import Logo from "../../assets/leechiuBlueLogo.png";
import { useState } from "react";
import { signupWithEmail } from "../../utils/firebase.utils";
import { createUserDoc, checkUser } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { loadUser } from "../../store/userSlice";
import { useDispatch } from "react-redux";

function Signup() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const signUp = async () => {
    const { email, password, displayName } = formData;
    try {
      const res = await signupWithEmail(email, password);
      if (res) {
        const userCheck = await checkUser(res.user);

        if (!userCheck) {
          await createUserDoc(res.user, { displayName, roles: [] });
          dispatch(
            loadUser({ email, displayName, uid: res.user.uid, roles: [] })
          );
          navigate("/dashboard");
        } else {
          alert("User already exists, sign in instead");
        }
      }
    } catch (error) {
    }
  };

  const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    //confirm if passwords match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    //confirm if password meets requirements
    if (!passwordRegEx.test(formData.password)) {
      setError(
        "Password must contain at least 8 characters, including at least 1 letter and 1 number"
      );
      return;
    }

    //confirm if email is valid
    if (!formData.email.includes("@leechiu.com")) {
      setError("Invalid email");
      return;
    }

    //sign up user
    signUp();
    setFormData({});
    setError("");
  };

  return (
    <div className="bg-[url('https://images.unsplash.com/photo-1517048676732-d65bc937f952?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-cover bg-no-repeat">
      <div className="flex items-center justify-center h-screen bg-white bg-opacity-30">
        <div className="loginform">
          <img src={Logo} alt="leechiu logo" className="w-60" />
          <div className="text-blue-800 font-semibold mb-4">My HRMS Access</div>
          <div>
            <form
              className="flex flex-col items-center gap-2 "
              onSubmit={handleSubmit}
            >
              <InputBox
                type="text"
                placeholder="Enter Full Name"
                label="Display Name"
                name="displayName"
                onChange={handleChange}
                value={formData.displayName || ""}
                required={true}
              />
              <InputBox
                type="email"
                placeholder="Email Address"
                label="Enter Email Address"
                name="email"
                onChange={handleChange}
                value={formData.email || ""}
                required={true}
              />
              <InputBox
                type="password"
                placeholder="Password"
                label={"Enter Password"}
                name="password"
                onChange={handleChange}
                value={formData.password || ""}
                required={true}
              />
              <InputBox
                type="password"
                placeholder="Confirm password"
                label={"Confirm Password"}
                name="confirmPassword"
                onChange={handleChange}
                value={formData.confirmPassword || ""}
                required={true}
              />
              <Button type="submit" label="Sign Up" />
            </form>
            {error && <div className="text-red-500">{error}</div>}
            <div>
              Already have an account? Login
              <a href="/login" className="text-blue-500">
                {" "}
                here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
