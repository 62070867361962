import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadUser } from "../store/userSlice";
import { useDispatch } from "react-redux";

function LogOut() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loadUser({}));
  };
  return (
    <>
      <button
        className="bg-red-500 py-2 px-8 rounded-lg text-white text-sm"
        onClick={handleLogout}
      >
        Logout
      </button>
    </>
  );
}

export default LogOut;
