import React from "react";
import SelectBox from "../../components/SelectBox";
import { addNewDoc } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export default function Clockin({ setSearchParams }) {
  const [selectedLocation, setSelectedLocation] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);

  const currentUser = useSelector((state) => state.userObject);

  const options = [
    { value: "office", label: "Office" },
    { value: "home", label: "Home" },
    { value: "field", label: "Field" },
  ];

  const handleSelect = (selected) => {
    setSelectedLocation(selected.value);
  };

  const handleSubmit = async () => {
    if (!selectedLocation) {
      toast.error("Please select a location");
      return;
    }

    setIsProcessing(true);

    try {
      await addNewDoc("attendance", {
        userId: currentUser.uid,
        name: currentUser.displayName,
        email: currentUser.email,
        location: selectedLocation,
        status: "clocked in",
        timestamp: new Date().toLocaleString(),
      });
      setIsProcessing(false);
      handleCloseSplash();
      handleSendEmailNotification();
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }

    toast.success("Clocked in successfully");
  };

  const handleSendEmailNotification = async () => {
    const docObject = {
      requestor: currentUser.leader,
      recipientName: currentUser.displayName,
      recipientEmail: currentUser.email,
      subject: "Clock In Notification - " + currentUser.displayName,
      message: `Good day! ${
        currentUser.displayName
      } has clocked in today at ${new Date().toLocaleString()}, reporting ${selectedLocation} as location. Please be informed.`,
      link: "https://myhr.leechiu.app",
    };

    const sendEmail = async () => {
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",

        body: JSON.stringify(docObject),
      });
      console.log("Email sent");
    };
    sendEmail();
  };

  const handleCloseSplash = () => {
    setSearchParams((prev) => ({ ...prev, splash: false }));
  };

  return (
    <div className="bg-white rounded-lg p-2 w-80 h-96 text-left relative">
      <button className="close-button" onClick={handleCloseSplash}>
        Close
      </button>
      <div className="header">Clock In</div>
      <div className="mt-8 text-center font-bold">
        Where are you reporting today?
      </div>
      <div className="mt-8">
        <SelectBox
          label={"Select Location"}
          options={options}
          placeholder={"Select Location"}
          handleSelect={(selected) => handleSelect(selected)}
        />
      </div>

      <button className="blue-button w-full mt-8" onClick={handleSubmit}>
        {isProcessing ? "Processing..." : "Clock In"}
      </button>
    </div>
  );
}
