import React from "react";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import { useState, useEffect } from "react";
import {
  createNewDocument,
  uploadBatchDocs,
  getAllDocsSnap,
} from "../../utils/firebase.utils";
import EmployeeList from "./EmployeeList";
import { employeeData } from "../../assets/data";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import EmployeeCards from "./EmployeeCards";
import { useSearchParams } from "react-router-dom";
// import DownloadExcel from "../../components/DownloadExcel";

function Employees() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [addMode, setAddMode] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  //   useEffect(() => {
  //     //uploadbatch docs
  //     const upload = async () => {
  //       try {
  //         const res = await uploadBatchDocs("employees", employeeData);
  //         console.log(res);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };
  //   }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      await getAllDocsSnap("employees", (data) => {
        //arrange data by lastName
        data.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        });

        setEmployees(data);
        setIsLoading(false);
      });
    };
    fetchEmployees();
  }, []);

  if (isLoading) {
    return <div>Loading..</div>;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddEmployee = () => {
    setAddMode(!addMode);
  };

  const handleSave = () => {
    try {
      createNewDocument("employees", {
        ...formData,
        displayName: `${formData.lastName}, ${formData.firstName} ${formData.middleName}`,
        status: "pending approval",
      });
    } catch (error) {
      return;
    }
    setAddMode(false);
    setFormData({});
  };

  const handleViewDetails = () => {
    if (selectedEmployee.length === 0) {
      alert("Please select an employee");
      return;
    }
    navigate(`/employees/${selectedEmployee[0]}`);
  };

  const filteredItems = employees.filter((employee) => {
    return (
      employee.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.middleName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.department.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const currentItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNext = () => {
    if (currentPage * itemsPerPage >= employees.length) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage === 1) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  const numberOfPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="px-4 py-2 relative">
      <div className="header">Employee Management Module</div>
      <div className="text-left mt-4 mb-4 text-xs">
        <Button
          label={addMode ? "Cancel" : "Add Employee"}
          onClick={handleAddEmployee}
        />
      </div>

      <div className="text-left">
        <div className="text-xs text-gray-600">
          Total Records: {employees.length}
        </div>
      </div>

      <div className="text-left mt-4">
        <input
          className="border border-solid border-gray-300 w-60 rounded-md px-2 py-1 focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out text-xs"
          type="search"
          placeholder="Search for employee"
          onChange={handleSearch}
        />
      </div>

      <div className="mt-4 space-y-2">
        {currentItems.map((employee, id) => (
          <div key={id}>
            <EmployeeCards employee={employee} />
          </div>
        ))}

        {/* <EmployeeList
          setSelectedEmployee={setSelectedEmployee}
          selectedEmployee={selectedEmployee}
          employees={employees}
          setEmployees={setEmployees}
        /> */}
      </div>

      {/* <div className="text-left mt-8">
        <button className="blue-button" onClick={handleViewDetails}>
          View Details
        </button>
      </div> */}
      {/* <div className="mt-4 text-left text-xs absolute bottom-10 left-10">
        <Button label="View Details" onClick={handleViewDetails} />
      </div> */}

      {/* <div>
        <DownloadExcel
          label="Download Employee List"
          data={employees}
          filename="employee_list"
        />
      </div> */}

      <div className="mt-4">
        <div className="text-xs text-gray-600 font-bold">
          Page {currentPage} of {numberOfPages}
        </div>
      </div>

      <div className="flex items-center gap-2 mt-4">
        <button className="blue-button" onClick={handlePrevious}>
          Previous
        </button>
        <button className="blue-button" onClick={handleNext}>
          Next
        </button>
      </div>

      {addMode && (
        <Modal>
          <div className="mt-4 w-[700px] relative">
            <button
              className="close-button"
              onClick={() => {
                setAddMode(false);
                setFormData({});
              }}
            >
              Close
            </button>
            <div className="header text-left">Add a new employee</div>
            <form
              className="flex items-end gap-4 flex-wrap bg-white rounded-xl px-4 py-2"
              onSubmit={handleSave}
            >
              <InputBox
                label="Employee Number"
                placeholder="Assign Employee Number"
                name="employeeNumber"
                required={true}
                onChange={handleChange}
                value={formData.employeeNumber || ""}
              />
              <InputBox
                label="First Name"
                placeholder="Enter First Name"
                name="firstName"
                required={true}
                onChange={handleChange}
                value={formData.firstName || ""}
              />
              <InputBox
                label="Middle Name"
                placeholder="Enter Middle Name"
                name="middleName"
                onChange={handleChange}
                required={true}
                value={formData.middleName || ""}
              />
              <InputBox
                label="Last Name"
                placeholder="Enter Last Name"
                name="lastName"
                onChange={handleChange}
                required={true}
                value={formData.lastName || ""}
              />
              <InputBox
                label="Position"
                placeholder="Enter Position"
                name="position"
                onChange={handleChange}
                required={true}
                value={formData.position || ""}
              />
              <InputBox
                label="Department"
                placeholder="Enter Department"
                name="department"
                onChange={handleChange}
                required={true}
                value={formData.department || ""}
              />
              <InputBox
                type={"number"}
                label="Basic Salary"
                placeholder="Enter Basic Salary"
                name="basicSalary"
                onChange={handleChange}
                required={true}
                value={formData.basicSalary || ""}
              />

              <button className="blue-button">Save</button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Employees;
