import React from "react";

function Button({ label, onClick, ...otherprops }) {
  return (
    <div>
      <button className="blue-button" onClick={onClick} {...otherprops}>
        {label}
      </button>
    </div>
  );
}

export default Button;
