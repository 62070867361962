import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getAllDocsSnap } from "../../utils/firebase.utils";
import { useEffect } from "react";

export default function PayrollDetails({ employees, setSelectedEmployee }) {
  const handleSelection = (e) => {
    setSelectedEmployee(e);
  };

  const columns = [
    { field: "displayName", headerName: "Full Name", width: 200 },

    {
      field: "basicSalary",
      headerName: "Basic Salary",
      type: "text",
      width: 90,
    },
    {
      field: "halfMonth",
      headerName: "Half Month",
      type: "text",
      width: 90,
    },
    {
      field: "commission",
      headerName: "Comission",
      type: "text",
      width: 90,
    },
    {
      field: "incentives",
      headerName: "Incentives",
      type: "text",
      width: 90,
    },
    {
      field: "overtime",
      headerName: "Overtime",
      type: "text",
      width: 90,
    },
    {
      field: "reimbursement",
      headerName: "Reimbursement",
      type: "text",
      width: 90,
    },
    {
      field: "SSS",
      headerName: "SSS",
      type: "text",
      width: 90,
    },
    {
      field: "HDMF",
      headerName: "Pag-ibig",
      type: "text",
      width: 90,
    },
    {
      field: "Philhealth",
      headerName: "Philhealth",
      type: "text",
      width: 90,
    },
    {
      field: "otherDeductions",
      headerName: "Other Deductions",
      type: "text",
      width: 90,
    },
    {
      field: "withholdingTax",
      headerName: "Tax",
      type: "text",
      width: 90,
    },

    {
      field: "netPay",
      headerName: "Net Pay",
      type: "text",
      width: 90,
    },
  ];

  return (
    <div style={{ height: 400, width: 1100 }}>
      {/* <div className="text-left font-semibold">Employee Master List</div> */}
      <DataGrid
        rows={employees}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        sx={{
          overflowX: "scroll",
          "& .MuiDataGrid-cell": {
            fontSize: "11px",
            color: "black",
          },
          fontSize: "11px",
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        checkboxSelection
        onRowSelectionModelChange={handleSelection}
      />
    </div>
  );
}
