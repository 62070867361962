import { Divider } from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsIcon from "@mui/icons-material/Settings";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import UserAvatar from "./UserAvatar";
import {
  getAllNotificationsSnapByEmail,
  getAllDocs,
} from "../utils/firebase.utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { loadUser } from "../store/userSlice";
import { useNavigate } from "react-router-dom";

function NavBar({ isMobile }) {
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [mailShown, setMailShown] = useState(false);

  const currentUser = useSelector((state) => state.userObject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getNotifications = async () => {
      const unsubscribe = getAllNotificationsSnapByEmail(
        "notifications",
        currentUser.email,
        (docs) => {
          setNotifications(docs);
          setNotificationCount(docs.length);
          // toast.success(`You new notifications`);
        }
      );
      return unsubscribe;
    };

    getNotifications();
  }, []);

  const handleShowMails = () => {
    setMailShown(!mailShown);
  };

  const handleLogOut = () => {
    navigate("/login");

    dispatch(loadUser({}));
  };

  return (
    <div className="bg-gray-200 h-16 flex items-center justify-between px-4">
      <div className="flex items-center justify-start border-l-2 border-gray-400 px-4">
        <div className="hidden lg:block">
          <CallIcon />
        </div>
        <div className="ml-4 hidden lg:block">
          <div className="text-xs lg:text-lg">+632 88888 111</div>
          <div className="text-xs text-orange-600">Help & Support</div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2">
        <div className="relative">
          <MailOutlineIcon />
          <div
            className="rounded-full bg-green-900 px-2 scale-70  font-bold text-white absolute -top-3 -right-3 hover:cursor-pointer"
            onClick={handleShowMails}
          >
            {notificationCount === 0 ? null : notificationCount}
          </div>
          {mailShown && (
            <div className="absolute w-72 pb-4 bg-gray-300 -left-60 rounded-xl text-xs shadow-2xl border border-gray-500">
              <div className="font-bold uppercase text-gray-800 text-left p-2">
                Notifications
              </div>

              <div>
                {notifications.map((notification) => (
                  <div className="bg-gray-100 p-2 text-left">
                    <div className="uppercase font-semibold">
                      {notification.subject}
                    </div>

                    <div>Date: {notification.date}</div>
                    <Divider />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <NotificationsNoneIcon />
        <SettingsIcon />
        <UserAvatar email={currentUser.email || "user@leechiu.com"} />
        <button onClick={handleLogOut}>
          <LogoutIcon />
        </button>
      </div>
    </div>
  );
}

export default NavBar;
