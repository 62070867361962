import React from "react";
import { useNavigate } from "react-router-dom";

function EmployeeCards({ employee }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/employees/${employee.id}`);
  };
  return (
    <div onClick={handleClick} className="relative">
      <div className="bubble cursor-pointer hover:border hover:border-blue-900">
        <div className="flex items-center gap-2">
          <div>{employee.lastName}</div>
          <div>{employee.firstName}</div>
        </div>
        <div className="absolute top-0 right-0 ">
          <div className="px-2 py-1 text-xs capitalize">{employee.status}</div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeCards;
