import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getDocDataSnap,
  setDocData,
  deleteDocById,
} from "../../utils/firebase.utils";
import PayrollDetails from "./PayrollDetails";
import InputBox from "../../components/InputBox";
import Payslip from "./Payslip";
import DownloadExcel from "../../components/DownloadExcel";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import WhiteButton from "../../components/WhiteButton";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function DraftPayroll() {
  const { id } = useParams();
  const [payroll, setPayroll] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employeePayrollDetails, setEmployeePayrollDetails] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const currentUser = useSelector((state) => state.userObject);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayroll = async () => {
      const data = await getDocDataSnap("payrollRuns", id, (data) => {
        setPayroll(data);
        setFilteredData(data.payregister);
        setIsLoading(false);
      });
    };
    fetchPayroll();
  }, []);

  useEffect(() => {
    if (selectedEmployee.length > 0) {
      const data = payroll.payregister.filter(
        (item) => item.id === selectedEmployee[0]
      );
      setEmployeePayrollDetails(data[0]);
    }
  }, [selectedEmployee]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const formatter = (amount) => {
    return parseFloat(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  };

  const updatePayrollDetails = (e) => {
    setEmployeePayrollDetails({
      ...employeePayrollDetails,
      [e.target.name]: e.target.value,
    });
  };

  const updatePayroll = async (employeeData) => {
    const data = {
      ...payroll,
      payregister: payroll.payregister.map((item) => {
        if (item.id === selectedEmployee[0]) {
          return employeeData;
        }
        return item;
      }),
    };
    try {
      await setDocData("payrollRuns", id, data).then(() => {
        toast.success("Employee Payroll Updated");
      });
    } catch (error) {
    }

    setPayroll(data);
  };

  const handleSubmit = async () => {
    if (window.confirm("Are you sure to submit this payroll for approval? ")) {
      const data = {
        ...payroll,
        status: "submitted",
      };
      try {
        await setDocData("payrollRuns", id, data).then(() => {
          toast.success("Payroll Finalized and Submitted for Approval");
          navigate("/payroll");
        });
      } catch (error) {
      }
      setPayroll(data);
    } else {
      return;
    }
  };

  const handleDeleteDraft = async () => {
    if (window.confirm("Are you sure to delete this payroll draft?")) {
      try {
        navigate("/payroll");
        await deleteDocById("payrollRuns", id).then(() => {});
        toast.success("Payroll Draft Deleted");
      } catch (error) {
      }
    } else {
      return;
    }
  };

  const handleSearch = (e) => {
    const data = payroll.payregister.filter((item) =>
      item.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData(data);
  };

  const handleDeleteItem = async () => {
    if (selectedEmployee.length < 1) {
      return;
    }
    if (
      window.confirm(
        "Are you sure to delete this item? Take note that deleting is irreversible."
      )
    ) {
      const data = {
        ...payroll,
        payregister: payroll.payregister.filter(
          (item) => item.id !== selectedEmployee[0]
        ),
      };
      try {
        await setDocData("payrollRuns", id, data).then(() => {
          toast.success("Item Deleted");
        });
      } catch (error) {
      }
      setPayroll(data);
      setSelectedEmployee([]);
    } else {
      return;
    }
  };

  return (
    <div className="py-4 px-2 flex flex-col gap-4">
      <div>Draft Payroll for Payroll Run : {payroll.date}</div>
      <InputBox
        label="Search name"
        placeholder="Search name"
        onChange={handleSearch}
        type="search"
      />
      {selectedEmployee.length > 0 &&
        //check if there is admin access
        currentUser.roles.includes("admin") && (
          <div className="flex items-center gap-2">
            <Button label="Delete Item" onClick={handleDeleteItem} />
          </div>
        )}
      <div className="py-2 px-2 bg-white rounded-xl shadow-lg flex items-center justify-center">
        <PayrollDetails
          employees={filteredData}
          setSelectedEmployee={setSelectedEmployee}
        />
      </div>

      {selectedEmployee.length === 0 && (
        <div className="flex items-center gap-2">
          <Button
            label="Finalize and Submit for Approval"
            onClick={handleSubmit}
          />
          <WhiteButton label="Delete Draft" onClick={handleDeleteDraft} />
        </div>
      )}

      {selectedEmployee.length > 0 && (
        <div className="rounded-xl bg-white shadow-lg py-4 px-2">
          <Payslip
            payrollDetails={employeePayrollDetails}
            onChange={updatePayrollDetails}
            updatePayroll={updatePayroll}
            setEmployeePayrollDetails={setEmployeePayrollDetails}
            editDisabled={false}
          />
        </div>
      )}

      {selectedEmployee < 1 ? (
        <div className="text-left text-xs">
          <DownloadExcel
            data={payroll.payregister}
            filename={`payroll-${payroll.date}`}
            label={"Download Payroll Register"}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DraftPayroll;
