import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

function SearchBox() {
  const [focused, setFocused] = useState(false);

  return (
    <div className="w-[200px] m-auto relative">
      {/* <input
        type="search"
        className="border py-2 px-4 rounded-lg w-[200px]"
        placeholder="Search here"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <button className="absolute top-2 right-8 text-gray-400">
        {!focused ? <SearchIcon /> : null}
      </button> */}
    </div>
  );
}

export default SearchBox;
