import React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllDocs } from "../../utils/firebase.utils";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

function Compensation() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const [payrollRuns, setPayrollRuns] = useState([]);
  const [selectedPayrollRun, setSelectedPayrollRun] = useState({});
  const [payPeriods, setPayPeriods] = useState([]);
  const [paySlipData, setPaySlipData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployeeId = async () => {
      const employeeId = await getAllDocs("employees");

      //sort by surname
      employeeId.sort((a, b) => {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      });
      setEmployees(employeeId);
      const userId = employeeId.find(
        (employee) => employee.email === currentUser.email
      );
      setEmployeeInfo(userId);

      setEmployeeId(userId?.id);
    };
    fetchEmployeeId();
  }, []);

  useEffect(() => {
    const fetchPayrollRuns = async () => {
      const payrollRuns = await getAllDocs("payrollRuns");
      //filter only approved status

      const approvedRuns = payrollRuns.filter(
        (run) => run.status === "approved"
      );
      setPayrollRuns(approvedRuns);
    };
    fetchPayrollRuns();
  }, []);

  useEffect(() => {
    if (payrollRuns.length > 0) {
      const periods = payrollRuns.map((run) => run.date);
      setPayPeriods(periods);
      setSelectedPeriod(periods[0]);
    }
  }, [payrollRuns]);

  useEffect(() => {
    if (selectedPeriod) {
      const selectedRun = payrollRuns.find(
        (run) => run.date === selectedPeriod
      );
      setSelectedPayrollRun(selectedRun);
    }
  }, [selectedPeriod, payrollRuns]);

  useEffect(() => {
    if (selectedPayrollRun.payregister) {
      const payregister = selectedPayrollRun.payregister;
      const paySlip = payregister.find((pay) => pay.id === employeeId);
      setPaySlipData(paySlip);
    }
  }, [selectedPayrollRun, employeeId]);

  const options = payPeriods.map((period) => (
    <option value={period}>{period}</option>
  ));

  const handleSelect = (e) => {
    setSelectedPeriod(e.target.value);
  };

  const converter = (data) => {
    const result = parseFloat(data).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return result;
  };

  const grossPay = () => {
    const parser = (data) => {
      if (data === undefined || data === null) {
        return 0;
      } else {
        return parseFloat(data);
      }
    };

    const basicSalary = parser(paySlipData?.basicSalary);
    const halfMonth = parser(paySlipData?.halfMonth);
    const commission = parser(paySlipData?.commission);
    const incentives = parser(paySlipData?.incentives);
    const overtime = parser(paySlipData?.overtime);
    const salaryAdjustment = parser(paySlipData?.salaryAdjustment);
    const lwop = parser(paySlipData?.lwop);
    const reimbursement = parser(paySlipData?.reimbursement) || 0;
    const thirteenMonthPay = parser(paySlipData?.thirteenMonthPay);
    const thirteenMonthPayTaxable = parser(
      paySlipData?.thirteenMonthPayTaxable
    );

    const totalGrossPay =
      halfMonth +
      thirteenMonthPay +
      thirteenMonthPayTaxable +
      commission +
      incentives +
      overtime +
      salaryAdjustment -
      lwop +
      reimbursement;

    return totalGrossPay;
  };

  const deductions = () => {
    const parser = (data) => {
      if (data === undefined || data === null) {
        return 0;
      } else {
        return parseFloat(data);
      }
    };

    const SSS = parser(paySlipData?.SSS);
    const Philhealth = parser(paySlipData?.Philhealth);
    const HDMF = parser(paySlipData?.HDMF);
    const maxicare = parser(paySlipData?.maxicare);
    const hdmf2 = parser(paySlipData?.hdmf2);
    const sssloan = parser(paySlipData?.sssloan);
    const hdmfloan = parser(paySlipData?.hdmfloan);
    const advances = parser(paySlipData?.advances);
    const otherDeductions = parser(paySlipData?.otherDeductions);
    const withholdingTax = parser(paySlipData?.withholdingTax);

    const totalDeductions =
      SSS +
      Philhealth +
      HDMF +
      maxicare +
      hdmf2 +
      sssloan +
      hdmfloan +
      advances +
      otherDeductions +
      withholdingTax;

    return totalDeductions;
  };

  const netPay = () => {
    const totalGrossPay = grossPay();
    const totalDeductions = deductions();

    const netPay = totalGrossPay - totalDeductions;
    return netPay;
  };

  const handlePrint = () => {
    // const printContents = document.getElementById("payslip").innerHTML;
    // const originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
    // document.body.innerHTML = originalContents;
    // navigate("/compensation");
  };

  const employeeListOptions = employees.map((employee) => {
    return (
      <option value={employee.id}>
        {employee.lastName} {employee.firstName}
      </option>
    );
  });

  const handleSelectEmployeeId = (e) => {
    setEmployeeId(e.target.value);
    const userId = employees.find((employee) => employee.id === e.target.value);
    setEmployeeInfo(userId);
  };

  return (
    <div className="text-left px-4">
      <div className="text-orange-600 font-semibold mb-4">Payslips</div>
      <div className="flex flex-col justify-start">
        {currentUser.roles.includes("reviewer") ? (
          <select
            className="border rounded-xl px-4 py-2 w-72 mb-8 "
            onChange={handleSelectEmployeeId}
          >
            {employeeListOptions}
          </select>
        ) : null}
        <label className="font-semibold text-xs text-gray-800">
          Select Pay Period below
        </label>

        <div>
          <select
            className="border rounded-xl px-4 py-2 "
            onChange={handleSelect}
            value={selectedPeriod ? selectedPeriod : "Select Pay Period"}
            placeholder="Select Pay Period"
          >
            {options}
          </select>
        </div>
      </div>

      {selectedPeriod && employeeInfo ? (
        <div
          className="bg-gray-100 p-4 rounded xl shadow-lg m-2 text-left flex flex-col gap-4 text-xs"
          id="payslip"
        >
          <div className="font-semibold text-sm">
            <h4>Employee Name: {employeeInfo.displayName}</h4>
            <div>Department: {employeeInfo.department}</div>
            <div>Position: {employeeInfo.position}</div>
            <div>Payroll for the period: {selectedPeriod}</div>
          </div>
          <div className="flex lg:flex-row flex-col items-start gap-10">
            <div>
              <h4 className="font-bold">Gross Pay</h4>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <tbody>
                  {Object.entries({
                    "Basic Salary": converter(paySlipData?.basicSalary),
                    "Half Month": converter(paySlipData?.halfMonth),
                    "13th Month Pay (Non Taxable)": converter(
                      paySlipData?.thirteenMonthPay || 0
                    ),

                    "13th Month Pay (Taxable)": converter(
                      paySlipData?.thirteenMonthPayTaxable || 0
                    ),
                    Commissions: converter(paySlipData?.commission),
                    Incentives: converter(paySlipData?.incentives),
                    Overtime: converter(paySlipData?.overtime),
                    "Salary Adjustment": converter(
                      paySlipData?.salaryAdjustment
                    ),
                    "Leave without pay": converter(paySlipData?.lwop),
                    "Reimbursement /Cash Advances": converter(
                      paySlipData?.reimbursement || 0
                    ),
                  }).map(([label, value], index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                        padding: "2px",
                      }}
                    >
                      <td
                        className="w-48"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {label}
                      </td>
                      <td
                        className="w-60 text-right"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {value}
                      </td>
                    </tr>
                  ))}
                  <tr className="font-bold">
                    <td
                      className="w-48"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Total Gross Pay
                    </td>
                    <td
                      className="w-60 text-right"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Php {converter(grossPay())}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div className="font-bold">Deductions</div>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <tbody>
                  {Object.entries({
                    SSS: converter(paySlipData?.SSS),
                    Philhealth: converter(paySlipData?.Philhealth),
                    PagIbig: converter(paySlipData?.HDMF),
                    HMO: converter(paySlipData?.maxicare),
                    "PagIbig 2": converter(paySlipData?.hdmf2),
                    "SSS Loan": converter(paySlipData?.sssloan),
                    "PagIbig Loan": converter(paySlipData?.hdmfloan),
                    Advances: converter(paySlipData?.advances),
                    "Other Deductions": converter(paySlipData?.otherDeductions),
                    "Withholding Tax": converter(paySlipData?.withholdingTax),
                  }).map(([label, value], index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                        padding: "2px",
                      }}
                    >
                      <td
                        className="w-48"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {label}
                      </td>
                      <td
                        className="w-60 text-right"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {value}
                      </td>
                    </tr>
                  ))}
                  <tr className="font-bold">
                    <td
                      className="w-48"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Total Deductions
                    </td>
                    <td
                      className="w-60 text-right"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Php {converter(deductions())}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-start">
            <div className="font-bold">Net Pay: Php {converter(netPay())}</div>
          </div>
          {/* <Button label="Print" onClick={handlePrint} /> */}
        </div>
      ) : (
        "Nothing Found"
      )}
    </div>
  );
}

export default Compensation;
