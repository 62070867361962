import React from "react";
import { getAllDocsSnap } from "../../utils/firebase.utils";
import { useState, useEffect } from "react";
import RequestCard from "../UserPages/RequestCard";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function HRRequests() {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeLink, setActiveLink] = useState("pending");
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getRequests = async () => {
      const requests = await getAllDocsSnap("requests", (requests) => {
        //arrange by dateRequested
        requests.sort((a, b) => {
          return new Date(b.dateRequested) - new Date(a.dateRequested);
        });

        setRequests(requests);
        setLoading(false);
      });
    };
    getRequests();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const links = ["all", "pending", "approved", "rejected"];

  const filteredRequests = requests.filter((request) => {
    if (activeLink === "all") {
      return true;
    }
    return request.status === activeLink;
  });

  //redirect user roles doesnt include hr maker or approver
  if (
    !currentUser.roles.includes("hr maker") &&
    !currentUser.roles.includes("hr approver")
  ) {
    return (
      <div>
        <div className="header">HR Requests</div>

        <div className="mt-4">
          You do not have access to this page. This page is dedicated for HR
          request approval.
        </div>

        <div>
          <Link to="/dashboard">
            <div className="mt-4 text-blue-500 underline cursor-pointer">
              Go back to dashboard
            </div>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="header">HR Requests</div>

      <div className="flex items-center gap-4 mt-4">
        {links.map((link, index) => (
          <div
            key={index}
            onClick={() => setActiveLink(link)}
            className={activeLink === link ? "active-pill" : "inactive-pill"}
          >
            <span className="capitalize">{link}</span>
          </div>
        ))}
      </div>

      <div className="mt-8 space-y-2">
        {filteredRequests.map((request, index) => (
          <div key={index}>
            <Link to={`/request/${request.id}`}>
              <RequestCard request={request} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
