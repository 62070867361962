import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDocData, setDocData } from "../../utils/firebase.utils";
import InputBox from "../../components/InputBox";
import Modal from "./Modal";

function EmploymentHistory() {
  const { id } = useParams();
  const [employee, setEmployee] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [addMode, setAddMode] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [employmentHistory, setEmploymentHistory] = React.useState([]);
  const [isEditing, setIsEditing] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    async function fetchEmployee() {
      try {
        const employee = await getDocData("employees", id);
        setEmployee(employee);
        setEmploymentHistory(employee.employmentHistory || []);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchEmployee();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const tableHeaders = [
    "Company Name",
    "Company Address",
    "Position",
    "Date Started",
    "Date Ended",
    "Salary",
    "Reason for Leaving",
    "Action",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (isEditing) {
      const updatedEmploymentHistory = employmentHistory.map(
        (employment, index) => {
          if (index === editIndex) {
            return formData;
          }
          return employment;
        }
      );

      const updatedEmployee = {
        ...employee,
        employmentHistory: updatedEmploymentHistory,
      };

      try {
        await setDocData("employees", id, updatedEmployee);
        setEmployee(updatedEmployee);
        setEmploymentHistory(updatedEmploymentHistory);
        setAddMode(false);
        setIsSubmitting(false);
        setIsEditing(false);
      } catch (error) {
        console.error(error);
        setIsSubmitting(false);
      }
      return;
    }

    try {
      const updatedEmploymentHistory = [...employmentHistory, formData];
      const updatedEmployee = {
        ...employee,
        employmentHistory: updatedEmploymentHistory,
      };
      await setDocData("employees", id, updatedEmployee);
      setEmployee(updatedEmployee);
      setEmploymentHistory(updatedEmploymentHistory);
      setAddMode(false);
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const handleEdit = (index) => {
    const employment = employmentHistory[index];
    setFormData(employment);
    setAddMode(true);
    setIsEditing(true);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      const updatedEmploymentHistory = employmentHistory.filter(
        (employment, i) => i !== index
      );
      const updatedEmployee = {
        ...employee,
        employmentHistory: updatedEmploymentHistory,
      };

      setDocData("employees", id, updatedEmployee)
        .then(() => {
          setEmployee(updatedEmployee);
          setEmploymentHistory(updatedEmploymentHistory);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="p-2 relative bg-white  rounded-xl flex flex-col items-start justify-start">
      <button
        className="bg-blue-900 text-white px-2 py-1 rounded-lg 
    "
        onClick={() => setAddMode(true)}
      >
        Add
      </button>
      <div className="text-left font-bold mt-2">Employment History</div>
      <div className="w-full">
        <table className="w-full">
          <thead>
            <tr>
              {tableHeaders.map((header) => (
                <th key={header} className="border border-gray-400 p-1">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {employee.employmentHistory?.map((employment, index) => (
              <tr key={index}>
                <td className="border border-gray-400 p-1">
                  {employment.companyName}
                </td>
                <td className="border border-gray-400 p-1">
                  {employment.companyAddress}
                </td>
                <td className="border border-gray-400 p-1">
                  {employment.position}
                </td>
                <td className="border border-gray-400 p-1">
                  {employment.dateStarted}
                </td>
                <td className="border border-gray-400 p-1">
                  {employment.dateEnded}
                </td>
                <td className="border border-gray-400 p-1">
                  {employment.salary}
                </td>
                <td className="border border-gray-400 p-1">
                  {employment.reasonForLeaving}
                </td>
                <td className="border border-gray-400 p-1 space-x-2">
                  <button
                    className="bg-blue-900 text-white px-2 py-1 rounded-lg"
                    onClick={() => handleEdit(index)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-900 text-white px-2 py-1 rounded-lg"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* modal for add */}
      {addMode && (
        <Modal>
          <div className="bg-white p-4 w-[800px] rounded-xl relative ">
            <button
              className="absolute top-2 right-2"
              onClick={() => {
                setAddMode(false);
                setFormData({});
                setIsEditing(false);
              }}
            >
              Close
            </button>

            <div className="text-left font-bold">Add Employment History</div>
            <div className="w-full">
              <form
                className="w-full space-y-2 mt-2 flex items-center justify-start flex-wrap gap-4"
                action=""
                onSubmit={handleSubmit}
              >
                <InputBox
                  label="Company Name"
                  name="companyName"
                  type="text"
                  placeholder="Company Name"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
                <InputBox
                  label="Company Address"
                  name="companyAddress"
                  type="text"
                  placeholder="Company Address"
                  value={formData.companyAddress}
                  onChange={handleChange}
                  required
                />
                <InputBox
                  label="Position"
                  name="position"
                  type="text"
                  placeholder="Position"
                  value={formData.position}
                  onChange={handleChange}
                  required
                />
                <InputBox
                  label="Date Started"
                  name="dateStarted"
                  type="date"
                  placeholder="Date Started"
                  value={formData.dateStarted}
                  onChange={handleChange}
                  required
                />
                <InputBox
                  label="Date Ended"
                  name="dateEnded"
                  type="date"
                  placeholder="Date Ended"
                  value={formData.dateEnded}
                  onChange={handleChange}
                  required
                />
                <InputBox
                  label="Salary"
                  name="salary"
                  type="text"
                  placeholder="Salary"
                  value={formData.salary}
                  onChange={handleChange}
                  required
                />
                <InputBox
                  label="Reason for Leaving"
                  name="reasonForLeaving"
                  type="text"
                  placeholder="Reason for Leaving"
                  value={formData.reasonForLeaving}
                  onChange={handleChange}
                  required
                />

                <button
                  className="bg-blue-900 text-white px-2 py-1 rounded-lg absolute bottom-5 right-10"
                  type="submit"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default EmploymentHistory;
