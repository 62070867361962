import React, { useEffect, useState } from "react";
import Modal from "../Employees/Modal";
import NewLeaveForm from "./NewLeaveForm";
import {
  getAllDocsSnap,
  queryAllDocsByFieldSnap,
} from "../../utils/firebase.utils";
import RequestCard from "./RequestCard";
import PageModal from "../../components/PageModal";
import RequestDetails from "./RequestDetails";
import { useSelector } from "react-redux";

export default function LeaveRequestPage() {
  const [addMode, setAddMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [viewMode, setViewMode] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const currentUser = useSelector((state) => state.userObject);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      await queryAllDocsByFieldSnap(
        "leaveRequests",
        "requestedBy",
        currentUser.email,
        (data) => {
          //arrange data by createdAt

          data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          setRequests(data || []);
          setIsLoading(false);
        }
      );
    };
    fetchRequests();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAdd = () => {
    setAddMode(!addMode);
  };

  const handleViewMode = (selected) => {
    setSelectedRequest(selected);
    setViewMode(!viewMode);
  };

  return (
    <div className="pt-8 text-gray-600 text-left">
      <div>
        <button className="blue-button" onClick={handleAdd}>
          Create a Leave Application
        </button>
      </div>

      {addMode && (
        <Modal>
          <NewLeaveForm setAddMode={setAddMode} />
        </Modal>
      )}

      <div>
        {requests.map((request, index) => (
          <div
            className="mt-2"
            key={index}
            onClick={() => handleViewMode(request)}
          >
            <RequestCard data={request} />
          </div>
        ))}
      </div>

      {viewMode && (
        <PageModal>
          <RequestDetails data={selectedRequest} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
