import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDocData, setDocData } from "../../utils/firebase.utils";
import Modal from "./Modal";
import InputBox from "../../components/InputBox";

function FamilyBackground() {
  const { id } = useParams();
  const [employee, setEmployee] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [addMode, setAddMode] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [familyBackground, setFamilyBackground] = React.useState([]);
  const [isEditing, setIsEditing] = React.useState(false);
  const [editIndex, setEditIndex] = React.useState(null);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    async function fetchEmployee() {
      try {
        const employee = await getDocData("employees", id);
        setEmployee(employee);
        setFamilyBackground(employee.familyBackground || []);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchEmployee();
  }, []);

  const tableHeaders = [
    "Name",
    "Relationship",
    "Date of Birth",
    "Occupation",
    "Employer",
    "Action",
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (isEditing) {
      const updatedFamilyBackground = familyBackground.map((family, index) => {
        if (index === editIndex) {
          return formData;
        }
        return family;
      });

      try {
        await setDocData("employees", id, {
          familyBackground: updatedFamilyBackground,
        });
        setFamilyBackground(updatedFamilyBackground);
        setAddMode(false);
        setIsEditing(false);
        setFormData({});
      } catch (error) {
        setError(error);
      }
    } else {
      try {
        await setDocData("employees", id, {
          familyBackground: [...familyBackground, formData],
        });
        setFamilyBackground([...familyBackground, formData]);
        setAddMode(false);
        setFormData({});
      } catch (error) {
        setError(error);
      }
    }
  };

  const handleDelete = async (index) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      const updatedFamilyBackground = familyBackground.filter(
        (family, i) => i !== index
      );

      try {
        await setDocData("employees", id, {
          familyBackground: updatedFamilyBackground,
        });
        setFamilyBackground(updatedFamilyBackground);
      } catch (error) {
        setError(error);
      }
    }
  };

  const handleEdit = async (index) => {
    const family = familyBackground[index];
    setFormData(family);
    setIsEditing(true);
    setEditIndex(index);
    setAddMode(true);
  };

  return (
    <div className="flex flex-col items-start justify-start gap-2">
      <div className="text-left font-bold text-leechiuBlue">
        Family Background
      </div>
      <div>
        <button className="blue-button" onClick={() => setAddMode(true)}>
          Add
        </button>
      </div>
      <div className="w-full">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-200">
              {tableHeaders.map((header) => (
                <th key={header} className="text-left py-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {familyBackground.map((family, index) => (
              <tr
                key={index}
                className="text-left border-b border-gray-200 capitalize"
              >
                <td className="p-1">{family.name}</td>
                <td>{family.relationship}</td>
                <td>{family.dateOfBirth}</td>
                <td>{family.occupation}</td>
                <td>{family.employer}</td>
                <td className="flex items-center gap-2 py-2">
                  <button
                    className="bg-blue-900 text-white px-2 py-1 rounded-lg"
                    onClick={() => {
                      setIsEditing(true);
                      setEditIndex(index);
                      setFormData(family);
                      setAddMode(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-900 text-white px-2 py-1 rounded-lg"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {addMode && (
        <Modal>
          <div className="w-[500px] relative">
            <button
              className="underline absolute top-2 right-2"
              onClick={() => {
                setAddMode(false);
                setIsEditing(false);
                setFormData({});
              }}
            >
              Close
            </button>
            <div className="font-bold text-leechiuBlue text-left mb-4">
              Add Family Background Information
            </div>
            <form
              className="flex flex-wrap gap-4 items-center justify-start"
              onSubmit={handleSubmit}
            >
              <InputBox
                label="Name"
                type={"text"}
                value={formData.name}
                name="name"
                onChange={handleChange}
                placeholder={"Full Name"}
                required
              />
              <InputBox
                label="Relationship"
                type={"text"}
                value={formData.relationship}
                name="relationship"
                onChange={handleChange}
                placeholder={"Father, Mother, etc."}
                required
              />
              <InputBox
                label="Date of Birth"
                type={"date"}
                value={formData.dateOfBirth}
                name="dateOfBirth"
                onChange={handleChange}
                placeholder={"YYYY-MM-DD"}
                required
              />
              <InputBox
                label="Occupation"
                type={"text"}
                value={formData.occupation}
                name="occupation"
                onChange={handleChange}
                placeholder={"Job Title"}
                required
              />
              <InputBox
                label="Employer"
                type={"text"}
                value={formData.employer}
                name="employer"
                onChange={handleChange}
                placeholder={"Company Name"}
                required
              />
              <button
                className="absolute bottom-0 right-0 bg-leechiuBlue text-white px-4 py-2 rounded"
                type="submit"
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default FamilyBackground;
