import React from "react";
import LeaveRequestsForApproval from "./LeaveRequestsForApproval";

export default function ApproverInboxMainPage() {
  const links = ["Leave Requests", "Expense Requests"];
  const [activeLink, setActiveLink] = React.useState("Leave Requests");

  return (
    <div className="text-gray-600 text-left">
      <div className="header">Approver's Inbox</div>

      <div className="mt-8 links-container">
        {links.map((link, index) => (
          <div
            key={index}
            className={
              activeLink === link ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(link)}
          >
            {link}
          </div>
        ))}
      </div>

      {activeLink === "Leave Requests" && <LeaveRequestsForApproval />}
    </div>
  );
}
