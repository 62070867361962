import React from "react";
import { FaComments } from "react-icons/fa";
import { FaPaperclip } from "react-icons/fa6";

export default function RequestCard({ request }) {
  return (
    <div className="bubble relative cursor-pointer hover:border hover:border-leechiuBlue">
      <div className="uppercase font-bold">
        {request.requestType}
        {request.requestType === "Others" && ` - ${request.description}`}
      </div>
      <div>{request.purpose}</div>
      <div>Requested by: {request.requestedBy}</div>
      <div>Date Required: {request.dateRequired}</div>
      <div>Date Requested: {request.dateRequested}</div>
      <div className="flex items-center gap-4 mt-4">
        {request.comments && (
          <div className="flex items-center gap-2">
            <FaComments className="inline" />
            {request.comments?.length} comments
          </div>
        )}
        {request.file && request.file !== "null" && (
          <div className="flex items-center gap-2">
            <FaPaperclip className="inline" />
            File Uploaded
          </div>
        )}
      </div>
      <div
        className={`absolute bottom-0 right-0 px-2 py-1 capitalize  rounded-br-xl ${request.status}`}
      >
        {request.status}
      </div>
    </div>
  );
}
