import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { ref, deleteObject, getStorage } from "firebase/storage";

import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  getDocs,
  writeBatch,
  deleteDoc,
  query,
  where,
  addDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAPeqSu6-u8ZLIBhZZ43R4pMapCjWaY8G8",
  authDomain: "hrmsleechiuv1.firebaseapp.com",
  projectId: "hrmsleechiuv1",
  storageBucket: "hrmsleechiuv1.appspot.com",
  messagingSenderId: "567969230321",
  appId: "1:567969230321:web:493a73a5b00c433987e850",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();

export default app;

export const storage = getStorage(app);

export const db = getFirestore();

export const signupWithEmail = async (email, password) => {
  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return response;
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      alert("Email already in use, please sign in instead");
    }
  }
};

export const checkUser = async (userAuth) => {
  if (userAuth) {
    //check if user is existing
    const userRef = doc(db, "users", userAuth.uid);
    const userSnapShot = await getDoc(userRef);

    if (!userSnapShot.exists()) {
      return false;
    } else {
      return true;
    }
  }
};

export const createUserDoc = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const colref = collection(db, "users");
  const userRef = doc(colref, userAuth.uid);
  const snapShot = await getDoc(userRef);

  if (!snapShot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await setDoc(userRef, {
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      alert(error.message);
    }
  }
  return userRef;
};

export const signInWithEmail = async (email, password) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      alert("User not found, please sign up instead");
    } else if (error.code === "auth/wrong-password") {
      alert("Incorrect password");
    } else {
      alert("Incorrect Email or Password. If no account yet, please sign up");
    }
  }
};

export const findUserDocbyId = async (id) => {
  const userRef = doc(db, "users", id);
  const snapShot = await getDoc(userRef);
  if (snapShot.exists()) {
    return snapShot.data();
  } else {
    return false;
  }
};

export const findUserDocByIdSnap = async (id, callback) => {
  const userRef = doc(db, "users", id);
  const unsubscribe = onSnapshot(userRef, (doc) => {
    if (doc.exists()) {
      callback(doc.data());
    } else {
      callback(false);
    }
  });

  return unsubscribe;
};

export const resetpassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    return error;
  }
};

export const createNewDocument = async (collectionName, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = await addDoc(collectionRef, data);
    return docRef;
  } catch (error) {
    alert(error.message);
    return error;
  }
};

export const addNewDoc = async (collectionName, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = await addDoc(collectionRef, data);
    return docRef;
  } catch (error) {
    alert(error.message);
    return error;
  }
};

export const getAllDocsSnap = (collectionName, callback) => {
  try {
    const collectionRef = collection(db, collectionName);

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const getAllNotificationsSnapByEmail = (
  collectionName,
  email,
  callback
) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(
      collectionRef,
      where("email", "==", email),
      where("isRead", "==", false),
      orderBy("date")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];

      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      callback(docs);
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const uploadBatchDocs = async (collectionName, data) => {
  try {
    const batch = writeBatch(db);
    data.forEach((item) => {
      const docRef = doc(collection(db, collectionName));
      batch.set(docRef, item);
    });
    await batch.commit();
    alert("Batch upload successful");
    return true;
  } catch (error) {
    console.error(error.message);
    return error;
  }
};

export const getDocData = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  } catch (error) {
    return error;
  }
};

export const getDocDataSnap = async (collectionName, id, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        callback(docSnap.data());
      } else {
        callback(false);
      }
    });

    // Return the unsubscribe function so that you can stop listening when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error; // Re-throw the error for handling in the calling code
  }
};

export const setDocData = async (collectionName, id, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch (error) {
    return error;
  }
};

export const deleteDocById = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    await deleteDoc(docRef);
    return true;
  } catch (error) {
    return error;
  }
};

export const getAllDocs = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const getUserByEmail = async (collectionName, email) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    const docs = [];

    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs[0];
  } catch (error) {
    return error;
  }
};


export const queryAllDocsByFieldSnap = (
  collectionName,
  field,
  value,
  callback
) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where(field, "==", value));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      callback(docs);
    });
    return unsubscribe;
  } catch (error) {
    return error;
  }
};