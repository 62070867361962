import React from "react";

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AdminDashboard from "../AdminDashboard";
import { useSearchParams } from "react-router-dom";
import Modal from "../Employees/Modal";
import Clockin from "./Clockin";
import { useNavigate } from "react-router-dom";

import { FaUserClock } from "react-icons/fa";
import { FcMoneyTransfer } from "react-icons/fc";
import { GrDocumentTransfer } from "react-icons/gr";
import { FaPersonSwimming } from "react-icons/fa6";

function Dashboard() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const splash = searchParams.get("splash");

  if (currentUser.roles.length < 1) {
    return (
      <div className="h-full flex items-center justify-center font-semibold text-lg">
        Congratulations, your account has been created. However, please wait for
        the activation of your access rights.
      </div>
    );
  }

  if (currentUser.roles.includes("admin")) {
    return (
      <div>
        <AdminDashboard />
      </div>
    );
  }

  const handleSplash = () => {
    setSearchParams({ splash: "true" });
  };

  if (currentUser.roles.includes("user")) {
    return (
      <div className="p-1 lg:p-4 text-left lg:w-[500px]">
        <div className="text-lg font-bold text-blue-900">
          Welcome to your Dashboard
        </div>
        {/* <div>{currentUser.displayName}</div> */}

        <div className="flex flex-col items-center justify-start border bg-gray-200 rounded-lg px-2 lg:px-8 py-2 mt-8">
          <div className="header">Quick Links</div>
          <div className="flex items-center gap-4 py-4">
            <div className="">
              <button
                className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
                onClick={handleSplash}
              >
                <FaUserClock className="text-4xl text-blue-800" />
                Clock In
              </button>
            </div>
            <div className="">
              <button
                className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
                onClick={() => navigate("/compensation")}
              >
                <GrDocumentTransfer className="text-4xl text-blue-800" />
                Payslips
              </button>
            </div>
            <div className="">
              <button
                className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
                onClick={() => navigate("/requests")}
              >
                <FcMoneyTransfer className="text-4xl text-blue-800" />
                HR Request
              </button>
            </div>
            <div className="">
              <button
                className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
                onClick={() => navigate("/leaves")}
              >
                <FaPersonSwimming className="text-4xl text-blue-800" />
                Leave Request
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-start border bg-gray-200 rounded-lg px-8 py-2 mt-8">
          <div className="header mt-8">Links for Department Approvers</div>
          <div className="flex items-center gap-4 py-4">
            <NavLink className="underline" to="/approverInbox">
              Leave Requests for Approval
            </NavLink>
          </div>
        </div>

        {splash === "true" && (
          <Modal>
            <Clockin setSearchParams={setSearchParams} />
          </Modal>
        )}
      </div>
    );
  }

  return <div>Dashboard</div>;
}

export default Dashboard;
